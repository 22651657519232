import React, { useState, useContext } from "react"

import gql from "graphql-tag"
import { useMutation, useQuery } from "@apollo/client"
import { Link, navigate } from "gatsby"

import {
  Avatar,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core"

import { makeStyles } from "@material-ui/core/styles"
import { Alert, AlertTitle } from "@material-ui/lab"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"

import FindPerson from "./FindPerson"
import UserContext from "../../context/UserContext"
import Copyright from "../utils/Copyright"

import useDidMountEffect from "../utils/hooks/useDidMountEffect"
import VerifyAccount from "./VerifyAccount"
import Sign from "./index"

import { useSiteMetadata } from "../utils/hooks/useSiteMetadata"
import Layout from "../../components/Layout/layout"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(3),
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    textDecoration: "none",
    color: "#0F52BA",
  },
}))

const ConvertUser = ({ thisToken, thatToken }) => {
  const { state, dispatch } = useContext(UserContext)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [password1, setPassword1] = useState("")
  const [password2, setPassword2] = useState("")
  const { sbuId } = useSiteMetadata()
  const [warning, setWarning] = useState("")
  const classes = useStyles()

  useDidMountEffect(() => {
    if (state.isLoggedIn) {
      navigate("/my")
    }
  }, [state.isLoggedIn])

  const [handleSubmit, { data, loading, error }] = useMutation(
    CONVERT_NEW_USER_MUTATION,
    {
      variables: {
        email: email,
        password: password1,
        sbuId: sbuId,
        preregToken: thisToken,
        preregId: thatToken,
      },
      onCompleted: data => {
        dispatch({ type: "SET_LOGIN" })
        console.log(data)
      },
    }
  )

  const checkCharacters = () => {
    var decimal =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/

    if (password1.match(decimal)) {
      setPassword(password1)
      return true
    } else {
      setWarning(
        "Please use a minimum of 8 characters including a number, a capitalized letter, a lowercase letter, and a symbol character like #$%^&*!."
      )
      return false
    }
  }

  const checkPassword = () => {
    if (password1 == "") {
      setWarning("Please enter a password")
      return false
    } else if (password2 == "") {
      setWarning("Please confirm password")
      return false
    } else if (password1 != password2) {
      setWarning("Passwords do not match. Please try again.")
      return false
    } else {
      setPassword(password1)
      return true
    }
  }

  const validate = () => {
    checkPassword() && checkCharacters() && handleSubmit()
  }

  return (
    <Container maxWidth="sm">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up Here
        </Typography>
        <Typography component="p" variant="body1">
          Did you receive a custom quote or a link to create a new account? Sign
          up here to activate your account and continue.
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={event => {
            event.preventDefault()
            validate(event)
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                value={firstName}
                onChange={event => setFirstName(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                value={lastName}
                onChange={event => setLastName(event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={event => setEmail(event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                helperText="Please use a minimum of 8 characters including a number, a capitalized letter, a lowercase letter, and a symbol character like #$%^&*!."
                id="password1"
                autoComplete="current-password"
                value={password1}
                onChange={event => setPassword1(event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Confirm Password"
                type="password"
                id="password2"
                autoComplete="current-password"
                value={password2}
                onChange={event => setPassword2(event.target.value)}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={
              loading || !email.trim() || !password1.trim() || !password2.trim()
            }
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            {warning.length > 3 && (
              <Alert onClose={() => setWarning("")} severity="warning">
                {warning}
              </Alert>
            )}
            <Grid item>
              <Link className={classes.link} to="/my">
                {"Already have an account? Sign in here."}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  )
}

const CONVERT_NEW_USER_MUTATION = gql`
  mutation (
    $preregToken: String!
    $preregId: String!
    $businessUnitId: Int!
    $email: String!
    $password: String!
  ) {
    convertPreregToUser(
      preregToken: $preregToken
      preregId: $preregId
      businessUnitId: $businessUnitId
      email: $email
      password: $password
    ) {
      message
      user {
        id
        isVerified
        active
      }
      sbu {
        id
        primaryEmail
        homepage
      }
    }
  }
`

export default ConvertUser

import React from "react"
import ConvertUser from "../components/Sign/ConvertUser"
import { useLocation } from "@reach/router"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"

const Convert = props => {
  const location = useLocation()
  var searchParams = new URLSearchParams(location.search)
  var thisToken = searchParams.getAll("this")
  var thatToken = searchParams.getAll("that")

  return (
    <Layout>
      <SEO title="Welcome" />
      <ConvertUser thisToken={thisToken} thatToken={thatToken} />
    </Layout>
  )
}

export default Convert
